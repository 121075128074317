@import url("https://fonts.googleapis.com/css?family=Overpass");



.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 40px;
    //width: 480px;
    border: 0px solid rgb(100, 100, 100);
    border-radius: 6px;
    &:focus-within {
        border: 0px solid #969696;
    }
    input {
        flex: 1;
        border: none;
        height: 42px;
        font-size: 17px;
        padding: 4px 0 4px 0;
        &:focus {
            outline: transparent;
        }
    }
}
/* .form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
} */

#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
    .tag-title {
        margin-top: 3px;
    }
    .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: #0052cc;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
    }
}

@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}

.download-link {
    margin-left: 20px;
    cursor: pointer;
}