.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Home Form Filter Title */
.form-title {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top:80px;
  margin-bottom: -130px
}

.page-title {
  margin-left: 20px;
  color: white;
  font-size: 50px; 
  margin-bottom: 0px
}

.main-page-title {
  margin-left: -39px;
  margin-right: -39px;
  margin-bottom: -80px;
  padding-left:39px; 
  padding-right:39px;
  margin-top:-80px
}

.secondary-page-title {
  margin-left: -39px; 
  margin-right: -39px; 
  margin-bottom: -148px; 
  padding-bottom: 245px;
  padding-left:39px; 
  padding-right:39px; 
  margin-top:-80px;
}

.page-description{
  width: 60%;
  color: white;
  margin-bottom: 20px
}

.archivedStudioKw{
  background-color: #c4ccd4;
}

.btn-sm.btn.btn-light.disabled:hover{
  cursor: not-allowed;
}

#brandSelection{
  border: 0px;
  padding: 0.6rem;
  border-radius: 7px;
  box-shadow: 0 1px 1px #e1e4ea
}

#brandSelection:focus{
  border: 0px;
  box-shadow: 0 3px 3px #e1e4ea
}
.modal-90w {
  width: 90%;
  max-width: 90% !important;
}