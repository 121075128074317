.AppTaggingTool {
  text-align: left;

  .card-header .btn-link {
    font-size: 20px;
    color: inherit;
    text-decoration: none;
    box-shadow: none;
  }

  a {
    color: #212529 !important;
  }

  .MenuItems {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    a {
      color: inherit;
      display: block;
      padding: 4px 0;
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .listing-checkboxes {
    font-size: 2rem;
    text-align: center;
    > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .SeoConfigurationForm {
    max-width: 1920px;
  }
  .section {
    margin-bottom: 80px;
  }
  .expand-button {
    display: inline-flex;
    align-items: center;
    padding: 2px 6px;
    font-weight: bold;
    &:not(:disabled) {
      cursor: pointer;
    }
    span {
      margin-right: 8px;
    }
  }
  fieldset.w-seo-control-field {
    padding: 0 4px;
    font-size: 1rem;
    line-height: 1.5;
  }

  .overlay {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.5);
  }

  .tiny-modal {
    position: fixed;
    z-index: 999;
    left: 16px;
    right: 16px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid gray;
    padding: 24px 8px 8px 8px;
    &__close {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .dimension-selector-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > input {
      width: 20px;
      margin-right: 8px;
    }
  }

  .operationsDiv {
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .other-dimension-label {
    width: 150px;
    font-size: 1rem;
    padding: 0 4px;
    overflow-wrap: break-word;
    white-space: normal;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .w-container-row {
    display: flex;
    align-items: center;
  }

  .w-seo-page-button.active-page {
    font-weight: 700;
    color: #218838;
  }
}
